import Landing from "./components/Landing";
import Menu from "./components/Menu";
import Cointoss from "./components/Cointoss";
import withWallet from "./components/withWallet";
import ConnectionProvider from "./components/ConnectionProvider";
import { ProgramProvider } from "./components/ProgramProvider";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import firebase from "./firebase";
// import { getAuth, signInAnonymously } from "firebase/auth";
// import { useAuthState } from "react-firebase-hooks/auth";

// const auth = getAuth(firebase);

// signInAnonymously(auth);

function App() {
  // const [user] = useAuthState(auth);
  const Toss = withWallet(Cointoss);

  return (
    <ConnectionProvider>
      <ProgramProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="menu" element={<Menu />} />
            <Route path="cointoss" element={<Toss />} />
          </Routes>
        </BrowserRouter>
      </ProgramProvider>
    </ConnectionProvider>
  );
}

export default App;
