import React from "react";
import { useState } from "react";
import { collection, getFirestore } from "firebase/firestore";
import firebase from "../firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { query, orderBy, limit } from "firebase/firestore";

export default function Leaderboard(props) {
  const db = getFirestore(firebase);
  const flipsRef = collection(db, "flips");
  const flipsQuery = query(flipsRef, orderBy("time", "desc"), limit(10));
  const [flips, loading, error] = useCollection(flipsQuery, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  return (
    <div className="scrollTable">
      <table className="table w-max mt-4 md:mt-6">
        <thead>
          <tr>
            <th className="bg-[#20253A] text-white">Address</th>
            <th className="bg-[#20253A] text-white">Results</th>
            <th className="bg-[#20253A] text-white">Time</th>
          </tr>
        </thead>
        <tbody>
          {flips ? (
            flips.docs.map((data) => {
              let doc = data.data();
              // console.log("time", doc.time);
              return (
                <tr key={data.id}>
                  <td className=" bg-[#20253A]/90">
                    <p className="truncate ... w-[100px] md:w-[300px] pixel text-white">
                      {doc.address}
                    </p>
                  </td>
                  <td className="bg-[#20253A]/90 pixel text-white">
                    {doc.results}
                  </td>
                  <td className="bg-[#20253A]/90 pixel">
                    <p className="truncate ... w-[100px] md:w-[300px] text-white">
                      {new Date(doc.time.seconds * 1000).toLocaleString()}
                    </p>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr></tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
