import React from 'react'
import Dots from '../images/dots.png'
import Monster from '../images/Black.gif'
import { Link } from 'react-router-dom'
import Music from "./music/music.mp3"
import { useEffect, useState } from 'react'

export default function Landing() {
  const [isPlaying, setIsPlaying] = useState(false)
  useEffect(() => {
    document.getElementById('audio').volume = 0.1;
  }, [])

  const handleMusicToggle = () => {
    if(isPlaying) {
      setIsPlaying(false)
      document.getElementById('audio').pause();
    } else {
      setIsPlaying(true)
      document.getElementById('audio').play();
    }
  }

  
  return (
    <>
                <div className='toggle-music flex flex-col items-center justify-center'>
              <p className='pixel text-[10px] text-white mb-1'>Toggle Music</p>
              <input onClick={handleMusicToggle} type="checkbox" className="toggle" />
            </div>

    <Link to="Menu" className='overflow relative cursor-pointer'>
      <audio id="audio" loop autoPlay ><source src={Music} type="audio/mp3"/></audio>
    <div id="landing" className='landing'>
    </div>
        <div className='flex flex-col items-center h-full justify-center relative z-20 arcade-container'>
            <p className=' text-[16px] lg:text-[24px] ash text-[#20253A] mt-12'>Ready Player 1</p>
            <h6 className='text-[80px] lg:text-[160px] pixel text-[#20253A] mt-12 arcade-text text-outline '>Darknet</h6>
            <p className='pixel text-[16px] tracking-[20px] lg:tracking-[40px] text-[#20253A] mt-12'>Powered by</p>
            {/* <img className='my-12 max-w-[80%] lg:w-[450px]' src={Dots}/> */}
            <div className='flex flex-row items-center gap-4 max-w-[80%] lg:w-[450px] my-12 justify-center'>
                <img className='w-[90px] hidden md:block' src={Monster}/>
                <img className='w-[90px] hidden md:block' src={Monster}/>
                <img className='w-[90px] hidden md:block' src={Monster}/>
                <img className='w-[90px] hidden md:block' src={Monster}/>
                <img className='w-[90px]' src={Monster}/>
                <img className='w-[90px]' src={Monster}/>
                <img className='w-[90px]' src={Monster}/>
            </div>
            <h6 className=' text-[40px] lg:text-[80px] pixel text-[#20253A] arcade-text drop-shadow-2xl'>The Antisocials</h6>
            <p className='text-white pixel text-[16px] lg:text-[24px] flicker mt-24'>[ Press anywhere to contuine]</p>
        </div>
    </Link>
    </>
  )
}
