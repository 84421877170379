import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";

const app = initializeApp({
  apiKey: "AIzaSyCb2QtLtjyFZ3uVqTroFBTu1Nfyczogf48",
  authDomain: "anti-socials.firebaseapp.com",
  projectId: "anti-socials",
  storageBucket: "anti-socials.appspot.com",
  messagingSenderId: "83573760054",
  appId: "1:83573760054:web:2155e8aa834c4f142eb897",
  measurementId: "G-6DM312KFQQ",
});

// console.log("app", app);

// const db = getFirestore(app);

// console.log("db", db);

export default app;

// const querySnapshot = await getDocs(collection(db, "flips"));
