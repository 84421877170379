import { BN, web3 } from "@coral-xyz/anchor";
import React, { useEffect, useState } from "react";
import Heads from "../images/COIN_HEADS.png";
import Tails from "../images/COIN_TAILS.png";
import Leaderboard from "./Leaderboard";
import Music from "./music/music.mp3";
import Spin from "./music/Spin.mp3";
import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from "@solana/spl-token";

export default function Cointoss(props) {
  const [state, setState] = useState({
    result: "",
    resultText: "",
    userSelection: "Heads",
    amountToBet: "0.001",
    isPlaying: false,
    isSpinning: false,
    isTransacting: false,
    isSol: true,
    json: {},
  });

  async function coinToss() {
    if (props.wallet && props.config && props.program) {
      document.getElementById("audioSpin").play();
      setState({
        ...state,
        resultText: "",
        isSpinning: true,
        isTransacting: true,
      });
      const flip = web3.Keypair.generate();
      const myFlip = state.userSelection.toLowerCase() === "heads" ? 1 : 0;
      let myAmount = 0.001;
      if (state.amountToBet.toLocaleLowerCase() === "house max") {
        if (state.isSol) {
          myAmount = 2.0;
        } else {
          myAmount = 5000000;
        }
      } else {
        myAmount = parseFloat(state.amountToBet);
      }
      console.log("My flip", myFlip, "My amount", myAmount);
      try {
        console.log("running flip");
        if (state.isSol) {
          const flipTx = await props.program.methods
            .flipCoinSol(new BN(myFlip), new BN(myAmount * 1e9))
            .accounts({
              config: props.config.configSol,
              flip: flip.publicKey,
              authority: props.config.authority,
              oracle: props.config.oracle,
              treasury: props.config.treasury,
              systemProgram: web3.SystemProgram.programId,
            })
            .signers([flip])
            .rpc();
          console.log("Your transaction signature", flipTx);
        } else {
          const flipTx = await props.program.methods
            .flipCoin(new BN(myFlip), new BN(myAmount))
            .accounts({
              config: props.config.configBonk,
              flip: flip.publicKey,
              authority: props.config.authority,
              oracle: props.config.oracle,
              treasury: props.config.treasury,
              authoritySplAccount: await getAssociatedTokenAddress(
                props.config.bonkMint, // mint
                props.wallet.publicKey // owner
              ),
              configSplAccount: props.config.configBonkAccount,
              tokenProgram: TOKEN_PROGRAM_ID,
              systemProgram: web3.SystemProgram.programId,
            })
            .signers([flip])
            .rpc();
          console.log("Your transaction signature", flipTx);
        }

        let configSettings = props.config;
        if (state.isSol) {
          configSettings.sol = true;
          configSettings.myAmount = myAmount;
          configSettings.config = configSettings.configSol;
          configSettings.authority = props.wallet.publicKey;
          configSettings.treasurySplAccount = props.config.treasurySolAccount;
          configSettings.authoritySplAccount = await getAssociatedTokenAddress(
            props.config.solMint, // mint
            props.wallet.publicKey // owner
          );
          configSettings.configSplAccount = configSettings.configSolAccount;
        } else {
          configSettings.sol = false;
          configSettings.myAmount = myAmount;
          configSettings.config = configSettings.configBonk;
          configSettings.authority = props.wallet.publicKey;
          configSettings.treasurySplAccount = props.config.treasuryBonkAccount;
          configSettings.authoritySplAccount = await getAssociatedTokenAddress(
            props.config.bonkMint, // mint
            props.wallet.publicKey // owner
          );
          configSettings.configSplAccount = configSettings.configBonkAccount;
        }

        const response = await fetch("/api/flip", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            flipAccount: flip.publicKey,
            config: configSettings,
            myFlip: myFlip,
          }),
        });
        setState({ ...state, json: await response.json() });
      } catch (error) {
        console.log("Error", error);
        setState({ ...state, resultText: String(error) });
      }
    }
  }

  useEffect(() => {
    if (state.json) {
      if (!(Object.keys(state.json).length === 0)) {
        // console.log("state.json", state.json);
        if (state.json.error) {
          console.log("Error flip", state.json.error);
          setState({
            ...state,
            result: "",
            resultText: "error try again",
            isSpinning: false,
            isTransactin: false,
          });
        } else {
          if (state.json.win) {
            if (state.userSelection.toLocaleLowerCase() === "heads") {
              setState({ ...state, result: "heads" });
            } else {
              setState({ ...state, result: "tails" });
            }
            console.log("You won!");
          } else {
            if (state.userSelection.toLocaleLowerCase() === "heads") {
              setState({ ...state, result: "tails" });
            } else {
              setState({ ...state, result: "heads" });
            }
            console.log("You lost!");
          }
        }
      }
    }
  }, [state.json]);

  useEffect(() => {
    // console.log("result changed");
    if (state.result === "heads" || state.result === "tails") {
      document.querySelector(`.${state.result}`).style.animationName = "paused";
      setState({
        ...state,
        result: "",
        resultText: state.result,
        isSpinning: false,
        isTransacting: false,
      });
    }
  }, [state.result]);

  function handleMusicToggle() {
    if (state.isPlaying) {
      setState({ ...state, isPlaying: false });
      document.getElementById("audio").pause();
    } else {
      setState({ ...state, isPlaying: true });
      document.getElementById("audio").play();
    }
  }

  function handleBonkToggle() {
    if (state.isSol) {
      setState({ ...state, isSol: false });
      const betAmount = document.getElementById("betAmount");
      const userSelection = document.getElementById("userSelection");
      let arr = Array.prototype.slice.call(betAmount.children);
      arr.forEach((el) => {
        el.classList.remove("selected");
      });
      arr = Array.prototype.slice.call(userSelection.children);
      arr.forEach((el) => {
        el.classList.remove("selected");
      });
    } else {
      setState({ ...state, isSol: true });
      const betAmount = document.getElementById("betAmount");
      const userSelection = document.getElementById("userSelection");
      let arr = Array.prototype.slice.call(betAmount.children);
      arr.forEach((el) => {
        el.classList.remove("selected");
      });
      arr = Array.prototype.slice.call(userSelection.children);
      arr.forEach((el) => {
        el.classList.remove("selected");
      });
    }
  }

  function handleBetAmount(e) {
    if (e.target.nodeName === "P") {
      setState({ ...state, amountToBet: e.target.innerText });
      let arr = Array.prototype.slice.call(e.target.parentNode.children);
      arr.forEach((el) => {
        el.classList.remove("selected");
      });
      e.target.classList.toggle("selected");
    }
  }

  function handleUserSelection(e) {
    if (e.target.nodeName === "P") {
      setState({ ...state, userSelection: e.target.innerText });
      e.target.classList.toggle("selected");
      let arr = Array.prototype.slice.call(e.target.parentNode.children);
      arr.forEach((el) => {
        el.classList.remove("selected");
      });
      e.target.classList.toggle("selected");
    }
  }

  useEffect(() => {
    // console.log("state", state);
    if (document) {
      document.getElementById("audio").volume = 0.1;
      document.getElementById("audioSpin").loop = false;
    }
  }, [document]);

  return (
    <div className="overflow relative  h-full px-4">
      <div className="toggle-music flex flex-col items-center justify-center">
        <p className="pixel text-[10px] text-white mb-1">Toggle Music</p>
        <input onClick={handleMusicToggle} type="checkbox" className="toggle" />
      </div>
      <div id="landing" className="landing"></div>
      <audio id="audio" loop volume=".1" autoPlay>
        <source src={Music} type="audio/mp3" />
      </audio>
      <audio id="audioSpin" loop>
        <source src={Spin} type="audio/mp3" />
      </audio>

      <div className="flex  flex-col items-center  justify-center relative z-20 ">
        <p className=" text-[16px] lg:text-[24px] ash text-[#20253A] mt-6">
          DARK FLIP
        </p>
        <div className="max-w-screen-lg px-4">
          <div
            style={{
              animationPlayState: state.isSpinning ? "running" : "paused",
            }}
            id="coin"
            className={`${
              state.result
            } w-[150px] h-[150px] md:h-[400px] md:w-[400px] ${
              state.isTransacting ? "spinning" : ""
            } `}
            key={+new Date()}
          >
            <div className="side-a">
              <img src={Tails} />
            </div>
            {/* style={{transform: state.result === 'heads' ? 'rotateY(360deg)'  : '-180deg' }}  */}
            <div className={`side-b ${state.resultText}`}>
              <img src={Heads} />
            </div>
          </div>

          <div
            id="userSelection"
            onClick={(e) => handleUserSelection(e)}
            className="flex flex-col gap-4 md:gap-6 md:flex-row items-center w-full justify-between"
          >
            <p className="w-[220px]  text-[20px] transition opacity-50 hover:opacity-100 h-[45px] md:h-[60px] md:text-[30px] pixel flex flex-col items-center justify-center rounded-[4px] text-white bg-[#20253A]">
              Heads
            </p>
            <p className="w-[220px]  text-[20px] transition opacity-50 hover:opacity-100 h-[45px] md:h-[60px] md:text-[30px] pixel flex flex-col items-center justify-center rounded-[4px] text-white bg-[#20253A]">
              Tails
            </p>
          </div>
          <p className=" text-[16px] lg:text-[24px] ash text-center text-[#20253A] mt-4">
            {state.resultText}
          </p>
          <p className="text-[#20253A] pixel text-[16px] lg:text-[24px] mx-auto text-center flicker my-6 md:my-16">
            [ Choose Amount ]
          </p>
          <div
            id="betAmount"
            onClick={(e) => handleBetAmount(e)}
            className="flex flex-col md:flex-row items-center  gap-2 md:gap-6"
          >
            <p
              className={`${
                state.isSol
                  ? "text-[20px] md:text-[26px]"
                  : "text-[12px] md:text-[18px]"
              } transition pixel text-white/50 hover:text-white/100 hover:bg-[#20253A] px-4 rounded-[4px]`}
            >
              {state.isSol ? "0.10 SOL" : "250000 BONK"}
            </p>
            <p
              className={`${
                state.isSol
                  ? "text-[20px] md:text-[26px]"
                  : "text-[12px] md:text-[18px]"
              } transition pixel text-white/50 hover:text-white/100 hover:bg-[#20253A] px-4 rounded-[4px]`}
            >
              {state.isSol ? "0.25 SOL" : "500000 BONK"}
            </p>
            <p
              className={`${
                state.isSol
                  ? "text-[20px] md:text-[26px]"
                  : "text-[12px] md:text-[18px]"
              } transition pixel text-white/50 hover:text-white/100 hover:bg-[#20253A] px-4 rounded-[4px]`}
            >
              {state.isSol ? "0.50 SOL" : "1000000 BONK"}
            </p>
            <p
              className={`${
                state.isSol
                  ? "text-[20px] md:text-[26px]"
                  : "text-[12px] md:text-[18px]"
              } transition pixel text-white/50 hover:text-white/100 hover:bg-[#20253A] px-4 rounded-[4px]`}
            >
              {state.isSol ? "1.00 SOL" : "2000000 BONK"}
            </p>
            <p
              className={`${
                state.isSol
                  ? "text-[20px] md:text-[26px]"
                  : "text-[12px] md:text-[18px]"
              } transition pixel text-white/50 hover:text-white/100 hover:bg-[#20253A] px-4 rounded-[4px]`}
            >
              HOUSE MAX
            </p>
          </div>
          <div className="flex flex-col gap-4 md:gap-6 md:flex-row items-center w-full justify-around mt-4 md:mt-12s">
            <div className="flex flex-col md:flex-row items-center justify-center w-18">
              <p className="pixel text-[12px] md:text-[20px] text-white mb-1">
                Toggle Bonk
              </p>
              <input
                onClick={handleBonkToggle}
                type="checkbox"
                className="toggle md:ml-4"
              />
            </div>
            <button
              className="w-[240px] md:h-[60px] h-[45px] text-white text-[20px] md:text-[30px] pixel flex flex-col items-center justify-center rounded-[4px] bg-[#20253A]"
              id="btn"
              onClick={coinToss}
            >
              Coin Toss
            </button>
          </div>
          <div className="overflow-x-auto">
            <p className=" text-[16px] lg:text-[24px] text-center mt-4 md:mt-6 pixel text-white">
              Latest Tosses
            </p>
            <Leaderboard />
          </div>
        </div>

        <p className="text-white pixel text-[12px] lg:text-[16px] mt-12 md:mt-16 mb-12">
          [ 2023 all rights reserved ]
        </p>
      </div>
    </div>
  );
}
