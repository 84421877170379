import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  WalletMultiButton,
  WalletDisconnectButton,
} from "@solana/wallet-adapter-react-ui";
import Cointoss from "./Cointoss";
import { useConfig, useProgram } from "../components/ProgramProvider";

const withWallet = (Component) => (props) => {
  const wallet = useWallet();
  const config = useConfig();
  const program = useProgram();

  // console.log("withWallet ran", wallet, config, program);

  if (wallet.publicKey) {
    return (
      <Cointoss {...props} wallet={wallet} config={config} program={program} />
    );
  } else {
    return (
      <div className="overflow relative h-screen px-4 flex flex-col items-center justify-center">
        <div className="flex  flex-col items-center  justify-center relative z-20 ">
          <WalletMultiButton className="btn btn-neutral" />
        </div>
      </div>
    );
  }
};

export default withWallet;
