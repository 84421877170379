import React from "react";
import Dots from "../images/dots.png";
import Select from "../images/Black.gif";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Music from "./music/music.mp3";

export default function Menu() {
  useEffect(() => {
    document.getElementById("audio").volume = 0.1;
    document.getElementById("audio").play();
  }, []);
  const [isPlaying, setIsPlaying] = useState(true);

  const handleMusicToggle = () => {
    if (isPlaying) {
      document.getElementById("audio").pause();
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
      document.getElementById("audio").play();
    }
  };

  return (
    <div className="overflow relative cursor-pointer">
      <div className="toggle-music flex flex-col items-center justify-center">
        <p className="pixel text-[10px] text-white mb-1">Toggle Music</p>
        <input onClick={handleMusicToggle} type="checkbox" className="toggle" />
      </div>
      <audio loop id="audio">
        <source src={Music} type="audio/mp3" />
      </audio>
      <div id="landing" className="landing"></div>
      <div className="flex flex-col items-center h-[100vh] justify-center relative z-20 ">
        <p className="text-[#20253A] pixel text-[16px] lg:text-[24px] flicker my-16">
          [ Choose your mode ]
        </p>
        <div className="menu-container ">
          <Link
            to="/cointoss"
            className="flex flex-row items-center arcade-container menu-container-item"
          >
            <img
              className="mr-3 arcade-text w-[80px] menu-monster"
              src={Select}
            />
            <h6 className="text-[30px] lg:text-[60px] pixel text-[#20253A]  arcade-text text-center ">
              Darkflip
            </h6>
          </Link>

          <div className="flex flex-row items-center arcade-container menu-container-item ">
            <a
              href="https://dtm.shill-city.com/d/DW7gpVAuQ9Evk9uBiSPkCftxuYiwQBuTCVMxJfQuToSP"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-row items-center arcade-container menu-container-item"
            >
              <img
                className="mr-3 arcade-text w-[80px] menu-monster"
                src={Select}
              />
              <h6 className="text-[30px] lg:text-[60px] pixel text-[#20253A]  arcade-text text-center ">
                $Dust Machine
              </h6>
            </a>
          </div>

          <div className="flex flex-row items-center arcade-container menu-container-item ">
            <img
              className="mr-3 arcade-text w-[80px] menu-monster"
              src={Select}
            />
            <h6 className="text-[30px] lg:text-[60px] pixel text-[#20253A]  arcade-text text-center ">
              Coming Soon...
            </h6>
          </div>

          <a
            href="https://discord.gg/antisocials"
            target="_blank"
            className="flex flex-row items-center arcade-container menu-container-item"
          >
            <img
              className="mr-3 arcade-text w-[80px] menu-monster"
              src={Select}
            />
            <h6 className="text-[30px] lg:text-[60px] pixel text-[#20253A]  arcade-text text-center ">
              Discord
            </h6>
          </a>

          <a
            href="https://twitter.com/AntiSocialsJPEG"
            target="_blank"
            className="flex flex-row items-center arcade-container menu-container-item"
          >
            <img
              className="mr-3 arcade-text w-[80px] menu-monster"
              src={Select}
            />
            <h6 className="text-[30px] lg:text-[60px] pixel text-[#20253A]  arcade-text text-center ">
              Twitter
            </h6>
          </a>
        </div>

        <p className="text-white pixel text-[12px] lg:text-[16px] mt-24">
          [ 2023 all rights reserved ]
        </p>
      </div>
    </div>
  );
}
