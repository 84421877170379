"use client";

import { AnchorProvider, Idl, Program, web3 } from "@coral-xyz/anchor";
import { getAccount, getAssociatedTokenAddressSync } from "@solana/spl-token";
import {
  AnchorWallet,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react";
import { ConfirmOptions } from "@solana/web3.js";
import React, { useContext, useState, useEffect } from "react";

const configContext = React.createContext(null);
const programContext = React.createContext(null);

export function useConfig() {
  return useContext(configContext);
}

export function useProgram() {
  return useContext(programContext);
}

export function ProgramProvider({ children }) {
  const [program, setProgram] = useState(null);
  const [config, setConfig] = useState(null);
  const { connection } = useConnection();
  const wallet = useWallet();

  useEffect(() => {
    if (wallet.publicKey) {
      // console.log("Wallet public key", wallet.publicKey.toString());
      const provider = new AnchorProvider(connection, wallet, "confirmed");

      setProgram(
        new Program(
          {
            version: "0.1.0",
            name: "darknet",
            instructions: [
              {
                name: "initialize",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "splToken",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "splTokenTreasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [],
              },
              {
                name: "fundTreasury",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "splToken",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authoritySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [
                  {
                    name: "amount",
                    type: "u64",
                  },
                ],
              },
              {
                name: "fundTreasurySol",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [
                  {
                    name: "amount",
                    type: "u64",
                  },
                ],
              },
              {
                name: "adminSetMaxFlip",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "splToken",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authoritySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [
                  {
                    name: "amount",
                    type: "u64",
                  },
                ],
              },
              {
                name: "withdrawTreasury",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "splToken",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authoritySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [
                  {
                    name: "amount",
                    type: "u64",
                  },
                ],
              },
              {
                name: "flipCoin",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "flip",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authoritySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [
                  {
                    name: "side",
                    type: "u64",
                  },
                  {
                    name: "amount",
                    type: "u64",
                  },
                ],
              },
              {
                name: "flipCoinSol",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "flip",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [
                  {
                    name: "side",
                    type: "u64",
                  },
                  {
                    name: "amount",
                    type: "u64",
                  },
                ],
              },
              {
                name: "flipCoinOracle",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "flip",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authorityWallet",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authoritySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasurySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [
                  {
                    name: "result",
                    type: "u64",
                  },
                ],
              },
              {
                name: "startWar",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "war",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authoritySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [
                  {
                    name: "amount",
                    type: "u64",
                  },
                ],
              },
              {
                name: "cancelWar",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "war",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "wallet",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "walletSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasurySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [],
              },
              {
                name: "endWarOracle",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "warOne",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "warTwo",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "warOneWallet",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "warTwoWallet",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "warOneSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "warTwoSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasurySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [
                  {
                    name: "seed",
                    type: "u64",
                  },
                ],
              },
              {
                name: "startRaffle",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "raffle",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "ticket",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authoritySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [
                  {
                    name: "price",
                    type: "u64",
                  },
                ],
              },
              {
                name: "endRaffleOracle",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "raffle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [
                  {
                    name: "result",
                    type: "u64",
                  },
                ],
              },
              {
                name: "closeRaffleOracle",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "raffle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [],
              },
              {
                name: "buyRaffleTicket",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "raffle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "ticket",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authoritySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [],
              },
              {
                name: "redeemRaffleTicket",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "raffle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "ticket",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "wallet",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "walletSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasurySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [],
              },
              {
                name: "redeemRaffleTicketOracle",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "raffle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "ticket",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "wallet",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "authorityWallet",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "walletSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasurySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [],
              },
              {
                name: "oracleMurderTicket",
                accounts: [
                  {
                    name: "config",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "ticket",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "wallet",
                    isMut: true,
                    isSigner: true,
                  },
                  {
                    name: "oracle",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasury",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "walletSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "configSplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "treasurySplAccount",
                    isMut: true,
                    isSigner: false,
                  },
                  {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                  },
                  {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                  },
                ],
                args: [],
              },
            ],
            accounts: [
              {
                name: "Config",
                type: {
                  kind: "struct",
                  fields: [
                    {
                      name: "authority",
                      type: "publicKey",
                    },
                    {
                      name: "oracle",
                      type: "publicKey",
                    },
                    {
                      name: "treasury",
                      type: "publicKey",
                    },
                    {
                      name: "splToken",
                      type: "publicKey",
                    },
                    {
                      name: "splTokenTreasury",
                      type: "publicKey",
                    },
                    {
                      name: "maxFlip",
                      type: "u64",
                    },
                    {
                      name: "bump",
                      type: "u8",
                    },
                    {
                      name: "splBump",
                      type: "u8",
                    },
                  ],
                },
              },
              {
                name: "Flip",
                type: {
                  kind: "struct",
                  fields: [
                    {
                      name: "oracle",
                      type: "bool",
                    },
                    {
                      name: "wallet",
                      type: "publicKey",
                    },
                    {
                      name: "amount",
                      type: "u64",
                    },
                    {
                      name: "side",
                      type: "u64",
                    },
                    {
                      name: "result",
                      type: "u64",
                    },
                    {
                      name: "config",
                      type: "publicKey",
                    },
                    {
                      name: "sol",
                      type: "bool",
                    },
                  ],
                },
              },
              {
                name: "War",
                type: {
                  kind: "struct",
                  fields: [
                    {
                      name: "wallet",
                      type: "publicKey",
                    },
                    {
                      name: "amount",
                      type: "u64",
                    },
                    {
                      name: "spl",
                      type: "publicKey",
                    },
                  ],
                },
              },
              {
                name: "Raffle",
                type: {
                  kind: "struct",
                  fields: [
                    {
                      name: "oracle",
                      type: "bool",
                    },
                    {
                      name: "pot",
                      type: "u64",
                    },
                    {
                      name: "result",
                      type: "u64",
                    },
                    {
                      name: "timestamp",
                      type: "i64",
                    },
                    {
                      name: "tickets",
                      type: "u64",
                    },
                    {
                      name: "price",
                      type: "u64",
                    },
                    {
                      name: "spl",
                      type: "publicKey",
                    },
                  ],
                },
              },
              {
                name: "Ticket",
                type: {
                  kind: "struct",
                  fields: [
                    {
                      name: "wallet",
                      type: "publicKey",
                    },
                    {
                      name: "raffle",
                      type: "publicKey",
                    },
                    {
                      name: "number",
                      type: "u64",
                    },
                  ],
                },
              },
            ],
            errors: [
              {
                code: 6000,
                name: "NotEnoughFundsToFlip",
              },
              {
                code: 6001,
                name: "InvalidWarSeed",
              },
              {
                code: 6002,
                name: "RaffleExpired",
              },
              {
                code: 6003,
                name: "RaffleNotEnded",
              },
              {
                code: 6004,
                name: "NotEnoughFundsInTreasury",
              },
            ],
            metadata: {
              address: "DNTkTwAdzdE5C1yAG9uZszphCZfZcXMfpfiVCERDXFLo",
            },
          },
          "DNTkTwAdzdE5C1yAG9uZszphCZfZcXMfpfiVCERDXFLo",
          provider
        )
      );
    }
  }, [wallet, connection]);
  useEffect(() => {
    if (program) {
      const oracle = new web3.PublicKey(
        "seerVxDq475HxSPQZ1npaMf8HnoAydj9WStQH1zHqee"
      );

      const treasury = new web3.PublicKey(
        "Azpz1Arx7pTkG6WDThJFjp6PzLJoT2P9tUtw24XTcLaD"
      );

      const bonkMint = new web3.PublicKey(
        "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263"
      );

      const solMint = new web3.PublicKey(
        "So11111111111111111111111111111111111111112"
      );

      const configSol = new web3.PublicKey(
        "CRXufpdagEhzaDYm8yjMPSSkqkkffaRxxAxTZwM4SxKa"
      );
      const configBonk = new web3.PublicKey(
        "HnhFQHB6kfXpvkCK5hTv5HTr2JpR7q1Mg8QhYMXBv6fW"
      );

      const configBonkAccount = new web3.PublicKey(
        "HPij3kHof4Tos7Yi2972QsvyChEx2YNxoamvF7XGMTyx"
      );
      const configSolAccount = new web3.PublicKey(
        "CRXufpdagEhzaDYm8yjMPSSkqkkffaRxxAxTZwM4SxKa"
      );

      const treasuryBonkAccount = new web3.PublicKey(
        "DugY5Lt55vbMCizc8bG3T3eg7SbMWXQgN6ZdMsF1N3uV"
      );
      const treasurySolAccount = new web3.PublicKey(
        "9ZJ13Waj9eUaFUdmRQM1xs5eqBN9P7aS2DYGArkr2cxF"
      );

      setConfig({
        configSol,
        configBonk,
        oracle,
        treasury,
        configBonkAccount,
        configSolAccount,
        bonkMint,
        solMint,
        treasuryBonkAccount,
        treasurySolAccount,
      });
    }
  }, [program]);

  return (
    <configContext.Provider value={config}>
      <programContext.Provider value={program}>
        {children}
      </programContext.Provider>
    </configContext.Provider>
  );
}
